<template>
    <div
        class="grid gap-8 p-8 my-4 grid-cols1 md:grid-cols-12 lg:gap-16 md:my-8"
    >
        <div
            class="w-full md:col-span-4 min-h-0 md:min-h-[75dvh] bg-white bg-right bg-cover h-96 md:h-full rounded-2xl md:rounded-l-none md:-ml-8"
            :style="{
                backgroundImage: `url(${imageUrl})`,
            }"
        ></div>
        <div
            class="flex flex-col justify-center w-full space-y-8 md:col-span-8 md:max-w-lg"
        >
            <h1 v-if="heading">{{ heading }}</h1>
            <p v-if="text" class="h5">{{ text }}</p>

            <slot />
        </div>
    </div>
</template>

<script>
import imageUrl from '@assets/images/auth_hero.png';

export default {
    name: 'ColumnsWithImage',
    props: {
        heading: {
            type: String,
        },
        text: {
            type: String,
        },
    },
    computed: {
        imageUrl() {
            return imageUrl;
        },
    },
};
</script>
